import { render, staticRenderFns } from "./landing.vue?vue&type=template&id=31719b77&scoped=true&lang=pug"
import script from "./landing.vue?vue&type=script&lang=js"
export * from "./landing.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/landing/styles.css?88033041ea4530d4eb2b840ab739b25ccc?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./landing.vue?vue&type=style&index=1&id=31719b77&prod&lang=scss"
import style2 from "./landing.vue?vue&type=style&index=2&id=31719b77&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31719b77",
  null
  
)

export default component.exports