<template lang='pug'>
	.wrapper
		.hero-wrapper
			header.header
				.header__container.container._full-width
					.header__inner
						a(href='#hero')
							svg.header__logo-icon
								use(xlink:href='#icon-logo')
						nav.header__nav.menu
							button.menu__item.link(:aria-label="$t('landing.whatIsIt')" type='button' data-menu-modal-open='')
								| {{ $t('landing.whatIsIt') }}
								svg.menu__arrow-icon
									use(xlink:href='#icon-arrow-down')
							a.menu__item.link(:href='i.href' v-for='i in headerLinks' target='_blank' v-if='!(i.enHidden && locale == "en")') {{ i.title }}
						.header__buttons
							a.lang-switch.header__lang-switch(:data-lang-change='locale' href='javascript:void(0)' @click='changeLocale')
								svg.lang-switch__img.header__lang-img._mobile-hidden(width='86' height='50')
									use(:xlink:href=`'#icon-flag-'+ locale`)
								svg.lang-switch__img.header__lang-img._mobile-hidden._mobile(width='22' height='22')
									use(:xlink:href=`'#icon-flag-' + locale + '-round'`)
							button.header__login-button.button._sm._ghost(type='button' data-login-modal-open='') {{ $t('user.login-button') }}
						button.header__burger(type='button' data-menu-modal-open='')
							svg.header__burger-icon
								use(xlink:href='#icon-burger')

			section#hero.hero
				.hero__container.container
					.hero__top-content
						.hero__bulb-icon-wrapper
							svg.hero__bulb-icon
								use(xlink:href='#icon-bulb')
						svg.hero__arrow-icon
							use(xlink:href='#icon-arrow-big')
						svg.hero__arrow-short-icon
							use(xlink:href='#icon-arrow-short')
						h2.hero__title._top {{ $t('landing.sellContent') }}
					.hero__center-content
						h3.hero__sub-title {{ $t('landing.bySubscription') }}
						.hero__tags
							ul.hero__tags-list._top
								li.hero__tags-item(v-for='(i, index) in listHero' :class='`_${index+1}`')
									//-img.hero__tags-img(:src=`'/assets/img/landing/images/hero/tag-' + (index+1) + '.png'` alt='' aria-hidden='true' :width='i.size' :height='i.size')
									| {{ $t('landing.listHero['+index+']') }}

					.hero__bottom-content
						.hero__bottom-left-content
							p.hero__text
								span.hero__text-desktop(v-html="$t('landing.heroTextDesktop')")
								span.hero__text-mobile(v-html="$t('landing.heroTextMobile')")
							button.hero__button-begin.button( @click='goToLogin')
								span.hero__button-begin-desktop {{ $t('landing.startEarn') }}
								span.hero__button-begin-mobile {{ $t('landing.startUse') }}
							button.hero__button-demo.button._ghost(style='visibility: hidden')
								span.hero__button-demo-desktop {{ $t('landing.demo') }}
								span.hero__button-demo-mobile {{ $t('landing.demoAccess') }}
						.hero__bottom-right-content
							h2.hero__title._bottom {{ $t('landing.byPaywall') }}
							svg.hero__arrow-icon-down
								use(xlink:href='#icon-arrow-big')
							svg.hero__arrow-icon-down-short
								use(xlink:href='#icon-arrow-short-down')
							.hero__subscribers-list
								.hero__subscriber(data-auto-show='default' v-for='(i, index) in listSubscribers' style='opacity: 0')
									//-img.hero__subscriber-img(:src=`'/assets/img/landing/images/hero/subscriber-'+ (index+1) +'.png'` alt='' aria-hidden='true' width='35' height='35')
									.hero__subscriber-content
										p.hero__subscriber-text {{ i.text }}
										p.hero__subscriber-name {{ i.name }}
		section#animations.animations
			.animations__inner
				.animations__blue-wrapper
					.animations__container.container
						.animations__frame
							.animations__video
								.animations__video-wrapper
									.animations__video-inner
									//-img.animations__video-frame-image(src='@/assets/img/landing/images/animations/mockup-video-frame.png' width='378' height='756')
									//-img.animations__video-first-shot(v-if='locale == "ru"' src='@/assets/img/landing/images/animations/mockup.jpg' width='378' height='756')
									//-img.animations__video-first-shot(v-else src='@/assets/img/landing/images/animations/mockup-en.png' width='378' height='756')
									//-video.animations__video-frame-mockup(loop muted v-if='locale == "ru"')
										source(src='@/assets/img/landing/video/mockup.mp4' type='video/mp4')
									//-video.animations__video-frame-mockup(loop muted v-else)
										source(src='@/assets/img/landing/video/mockup-en.mp4' type='video/mp4')
							button.animations__video-btn(type='button')
								span.animations__video-btn-icon
									svg(width='22' height='25' viewbox='0 0 22 25')
										use(xlink:href='#icon-play')
								| {{ $t('landing.lookAt') }}
							.animations__tab._active._visible(data-video-scene='1')
								h2.animations__title(v-html='$t("landing.fitModel")')

								p.animations__accent-text {{ $t('landing.otherThanAd') }}
								.animations__decorations
									.animations__decorations-inner._tab-1
										//-img.animations__semi-circle-image(src='@/assets/img/landing/images/animations/semi-circle-1.png' width='522' height='308' alt='' aria-hidden='true')
										//-img.animations__woman(src='@/assets/img/landing/images/animations/tab-1-woman.png' width='390' height='545' alt='' aria-hidden='true')
										//-.animations__decorations-icons
											.animations__decorations-icon._tab-1-emoji-1.icon-special
												img.icon-special__img(src='@/assets/img/landing/images/animations/tab-1-emoji-1.png' width='55' height='55')
											.animations__decorations-icon._tab-1-emoji-2.icon-special
												img.icon-special__img(src='@/assets/img/landing/images/animations/tab-1-emoji-2.png' width='55' height='55')
											.animations__decorations-icon._tab-1-emoji-3.icon-special
												img.icon-special__img(src='@/assets/img/landing/images/animations/tab-1-emoji-3.png' width='55' height='55')
							.animations__tab._temp(data-video-scene='2')
								h2.animations__title._tab-2(v-html='$t("landing.aboutHealthy")')
								p.animations__accent-text
									span.animations__accent-text-wrapper(v-html='$t("landing.inPrivateChannel")')
								.animations__decorations
									//-.animations__decorations-inner._tab-2
										img.animations__semi-circle-image._tab-2(src='@/assets/img/landing/images/animations/semi-circle-2.png' width='522' height='="202"' alt='' aria-hidden='true')
										img.animations__woman._tab-2(src='@/assets/img/landing/images/animations/tab-2-woman.png' width='371' height='="582"' alt='' aria-hidden='true')
										.animations__decorations-icons
											.animations__decorations-icon._tab-2-emoji-1.icon-special
												img.icon-special__img(src='@/assets/img/landing/images/animations/tab-2-emoji-1.png' width='55' height='55')
											.animations__decorations-icon._tab-2-emoji-2.icon-special
												img.icon-special__img(src='@/assets/img/landing/images/animations/tab-2-emoji-2.png' width='55' height='55')
							.animations__tab._temp(data-video-scene='3')
								h2.animations__title(v-html='$t("landing.settingPaywall")')

								.animations__decorations
									//-.animations__decorations-inner._tab-3
										img.animations__semi-circle-image._tab-3(src='@/assets/img/landing/images/animations/semi-circle-3.png' width='537' height='245' alt='' aria-hidden='true')
										img.animations__woman._tab-3(src='@/assets/img/landing/images/animations/tab-3-woman.png' width='458' height='575' alt='' aria-hidden='true')
										.animations__decorations-icons
											.animations__decorations-icon._tab-3-emoji-1.icon-special
												img.icon-special__img(src='@/assets/img/landing/images/animations/tab-3-emoji-1.png' width='55' height='55')
											.animations__decorations-icon._tab-3-emoji-2.icon-special
												img.icon-special__img(src='@/assets/img/landing/images/animations/tab-3-emoji-2.png' width='55' height='55')
											.animations__decorations-icon._tab-3-emoji-3.icon-special
												img.icon-special__img(src='@/assets/img/landing/images/animations/tab-3-emoji-3.png' width='55' height='55')
							.animations__tab._temp(data-video-scene='4')
								h2.animations__title._tab-4(v-html='$t("landing.shareProfile")')
								p.animations__accent-text
									span.animations__accent-text-wrapper(v-html='$t("landing.onMyAdvice")')
								.animations__decorations
									//-.animations__decorations-inner._tab-4
										img.animations__semi-circle-image._tab-4(src='@/assets/img/landing/images/animations/semi-circle-4.png' width='837' height='305' alt='' aria-hidden='true')
										img.animations__woman._tab-4(src='@/assets/img/landing/images/animations/tab-4-woman.png' width='414' height='576' alt='' aria-hidden='true')
										.animations__decorations-icons
											.animations__decorations-icon._tab-4-emoji-1.icon-special
												img.icon-special__img(src='@/assets/img/landing/images/animations/tab-4-emoji-1.png' width='55' height='55')
							.animations__tab._temp(data-video-scene='5')
								h2.animations__title._tab-5(v-html='$t("landing.getSubscribers")')
								p.animations__accent-text
									span.animations__accent-text-wrapper {{ $t("landing.createGoodContent") }}
								.animations__decorations
									//-.animations__decorations-inner._tab-5
										img.animations__semi-circle-image._tab-5(src='@/assets/img/landing/images/animations/semi-circle-4.png' width='837' height='305' alt='' aria-hidden='true')
										img.animations__woman._tab-5(src='@/assets/img/landing/images/animations/tab-5-woman.png' width='362' height='564' alt='' aria-hidden='true')
										.animations__decorations-icons
											.animations__decorations-icon._tab-5-emoji-1.icon-special
												img.icon-special__img(src='@/assets/img/landing/images/animations/tab-5-emoji-1.png' width='62' height='62')
											.animations__decorations-icon._tab-5-emoji-2.icon-special
												img.icon-special__img(src='@/assets/img/landing/images/animations/tab-5-emoji-2.png' width='50' height='50')
											.animations__decorations-icon._tab-5-emoji-3.icon-special
												img.icon-special__img(src='@/assets/img/landing/images/animations/tab-5-emoji-3.png' width='54' height='54')
		section#how-it-works.how-it-works
			.how-it-works__container.container
				h2.how-it-works__title.title {{ $t("landing.worksForYou") }}
				p.how-it-works__text {{ $t("landing.appForYou") }}
				ul.how-it-works__list
					li.how-it-works__item(data-auto-show='default' v-for='(i, ind) in listHowItWorks' style='opacity: 0')
						//-.how-it-works__item-image-wrapper
							img.how-it-works__item-image(:src=`'/assets/img/landing/images/how-it-works/item-' + (ind+1) + '.jpg'` width='132' height='132')
						p.how-it-works__item-name {{ i }}

				p.how-it-works__text._bottom(v-html='$t("landing.ifLost")')

		section#superpowers.superpowers
			.superpowers__container.container
				h2.superpowers__title.title {{ $t("landing.superpowers.title") }}
				.superpowers__list-wrapper
					ul.superpowers__list
						li.superpowers__item
							.superpowers__item-icon-wrapper
								svg.superpowers__item-icon
									use(xlink:href='#icon-superpowers-1')
							p.superpowers__item-title  {{ $t("landing.superpowers.list[0]") }}
							p.superpowers__item-text._1(v-html='$t("landing.superpowers.list2[0]")')
						li.superpowers__item
							.superpowers__item-icon-wrapper
								svg.superpowers__item-icon
									use(xlink:href='#icon-superpowers-2')
							p.superpowers__item-title  {{ $t("landing.superpowers.list[1]") }}
							p.superpowers__item-text(v-html='$t("landing.superpowers.list2[1]")')
						li.superpowers__item
							.superpowers__item-icon-wrapper
								svg.superpowers__item-icon
									use(xlink:href='#icon-superpowers-3')
							p.superpowers__item-title  {{ $t("landing.superpowers.list[2]") }}
							p.superpowers__item-text(v-html='$t("landing.superpowers.list2[2]")')
						li.superpowers__item
							.superpowers__item-icon-wrapper
								svg.superpowers__item-icon
									use(xlink:href='#icon-superpowers-4')
							p.superpowers__item-title  {{ $t("landing.superpowers.list[3]") }}
							p.superpowers__item-text(v-html='$t("landing.superpowers.list2[3]")')
						li.superpowers__item
							.superpowers__item-icon-wrapper
								svg.superpowers__item-icon
									use(xlink:href='#icon-superpowers-5')
							p.superpowers__item-title  {{ $t("landing.superpowers.list[4]") }}
							p.superpowers__item-text(v-html='$t("landing.superpowers.list2[4]")')
						li.superpowers__item
							.superpowers__item-icon-wrapper
								svg.superpowers__item-icon
									use(xlink:href='#icon-superpowers-6')
							p.superpowers__item-title  {{ $t("landing.superpowers.list[5]") }}
							p.superpowers__item-text._mobile-sm(v-html='$t("landing.superpowers.list2[5]")')
						li.superpowers__item
							.superpowers__item-icon-wrapper
								svg.superpowers__item-icon
									use(xlink:href='#icon-superpowers-7')
							p.superpowers__item-title  {{ $t("landing.superpowers.list[6]") }}
							p.superpowers__item-text._sm(v-html='$t("landing.superpowers.list2[6]")')
						li.superpowers__item
							.superpowers__item-icon-wrapper
								svg.superpowers__item-icon
									use(xlink:href='#icon-superpowers-8')
							p.superpowers__item-title  {{ $t("landing.superpowers.list[7]") }}
							p.superpowers__item-text(v-html='$t("landing.superpowers.list2[7]")')

		section#payments.payments
			.payments__wrapper
				.payments__container.container
					.payments__inner
						.payments__bills-wrapper
							.payments__bill-item._ruble-1(data-auto-show='default' v-if='isLocaleRu')
								svg.payments__bill-img
									use(xlink:href='#icon-ruble-1')
							.payments__bill-item._ruble-2(data-auto-show='default' v-if='isLocaleRu')
								svg.payments__bill-img
									use(xlink:href='#icon-ruble-2')
							.payments__bill-item._dollar(data-auto-show='default')
								svg.payments__bill-img
									use(xlink:href='#icon-dollar')
							.payments__bill-item._euro(data-auto-show='default')
								svg.payments__bill-img._euro
									use(xlink:href='#icon-euro')
						.payments__grid
							.payments__content-left
								.payments__text-spaced-wrapper
									p.payments__text-spaced._left {{ $t("landing.payments.repeated") }}
									svg.payments__text-spaced-arrow
										use(xlink:href='#icon-arrow-medium')
									svg.payments__text-spaced-arrow-down
										use(xlink:href='#icon-arrow-medium-down')
							.payments__content-right
								p.payments__text-accent(v-html='$t("landing.payments.auto")')
								p.payments__text-spaced._right(v-html='$t("landing.payments.increase")')
								p.payments__text(v-html='$t("landing.payments.auto2")')
								button.payments__button-start.button._special( @click='goToLogin') {{ $t("landing.startEarn") }}

		section#calculator.calculator(data-calculator='')
			.calculator__container.container
				h2.calculator__title.title {{ $t("landing.calculator.title") }}
				.calculator__grid
					form.calculator__form
						//-img.calculator__decoration(src='@/assets/img/landing/images/calculator/decoration-circles.svg' width='502' height='539')
						.calculator__currencies(v-if='isLocaleRu')
							button.calculator__currency._active(type='button' :data-calculator-currency='$t("landing.calculator.rub")')
								span.calculator__currency-inner ₽
							button.calculator__currency(type='button' data-calculator-currency=' $')
								span.calculator__currency-inner $
							button.calculator__currency(type='button' data-calculator-currency=' €')
								span.calculator__currency-inner €
						.calculator__currencies(v-else)
							button.calculator__currency._active(type='button' data-calculator-currency='$')
								span.calculator__currency-inner $
							button.calculator__currency(type='button' data-calculator-currency='€')
								span.calculator__currency-inner €
						.calculator__body
							.calculator__body-item
								.calculator__body-text-wrapper
									p.calculator__body-text {{ $t("landing.calculator.amountPeople") }}
									p.calculator__body-amount
										span(data-calculator-subs-label='') 10000
										|  {{ $t("landing.calculator.people") }}
								.calculator__range-draggable(data-calculator-subs-range='')
							.calculator__body-item
								.calculator__body-text-wrapper
									p.calculator__body-text(v-html='$t("landing.calculator.monthPrice")')
									p.calculator__body-amount
										span(data-calculator-price-label='') 500
										span(data-calculator-currency-name='' v-if='isLocaleRu') {{ $t("landing.calculator.rubSign") }}
										span(data-calculator-currency-name='' v-else) $
								.calculator__range-draggable(data-calculator-price-range='')
							svg.calculator__emoji-ooo
								use(xlink:href='#icon-emoji-ooo')
							svg.calculator__emoji-dollar
								use(xlink:href='#icon-smile-dollar')
					.calculator__content
						svg.calculator__arrow-icon
							use(xlink:href='#icon-arrow-rounded')
						p.calculator__earnings
							| {{ $t("landing.calculator.canEarn") }}
							br
							|  {{ $t("landing.calculator.from") }}&nbsp;
							span.calculator__earnings-inner
								span.calculator__earnings-min(data-calculator-revenue-min='' data-auto-show='default')  20 000
								|  {{ $t("landing.calculator.to") }}&nbsp;
								span.calculator__earnings-max(data-calculator-revenue-max='' data-auto-show='default')  120 000
								span(data-calculator-result-currency='' v-if='isLocaleRu')
									| ₽
								span(data-calculator-result-currency='' v-else) $

							|  {{ $t("landing.calculator.monthly") }}
						p.calculator__conversion(v-html='$t("landing.calculator.desc")')
						button.calculator__button-start.button( @click='goToLogin') {{ $t("landing.startEarn") }}
		section#world.world
			.world__container.container
				h3.world__title.title {{ $t("landing.world.title") }}
				p.world__text {{ $t("landing.world.desc") }}
				.world__img

		section#how-to-start.how-to-start
			.how-to-start__container.container
				.how-to-start__inner
					h3.how-to-start__title.title(v-html='$t("landing.howtostart.title")')
					p.how-to-start__disclaimer
						svg.how-to-start__disclaimer-icon
							use(xlink:href='#icon-clock')
						span(v-html='$t("landing.howtostart.desc")')
					ol.how-to-start__steps-list
						li.how-to-start__steps-item._1
							.how-to-start__step-number-wrapper._1
								p.how-to-start__step-number 1
								.how-to-start__step-icon-wrapper._mic(data-auto-show='scale')
									svg.how-to-start__step-icon._mic
										use(xlink:href='#icon-mic')
								.how-to-start__step-icon-wrapper._strawberry(data-auto-show='scale')
									//-img.how-to-start__step-icon._strawberry(src='@/assets/img/landing/images/hero/tag-5.png')

								.how-to-start__step-icon-wrapper._cap(data-auto-show='scale')
									svg.how-to-start__step-icon._cap
										use(xlink:href='#icon-cap')
							p.how-to-start__step-text._1 {{ $t("landing.howtostart.list[0]") }}
						li.how-to-start__steps-item._2
							p.how-to-start__step-text._2 {{ $t("landing.howtostart.list[1]") }}
							.how-to-start__step-number-wrapper._2
								p.how-to-start__step-number 2
								.how-to-start__step-icon-wrapper._kiss(data-auto-show='scale')
									svg.how-to-start__step-icon._kiss
										use(xlink:href='#icon-smile-kiss')
								.how-to-start__step-icon-wrapper._happy(data-auto-show='scale')
									svg.how-to-start__step-icon._happy
										use(xlink:href='#icon-smile-happy')
								.how-to-start__step-icon-wrapper._love(data-auto-show='scale')
									svg.how-to-start__step-icon._love
										use(xlink:href='#icon-smile-love')
						li.how-to-start__steps-item._3
							.how-to-start__step-number-wrapper._3
								p.how-to-start__step-number 3
								.how-to-start__step-icon-wrapper._fire(data-auto-show='scale')
									svg.how-to-start__step-icon._fire
										use(xlink:href='#icon-fire')
								.how-to-start__step-icon-wrapper._smile-dollar(data-auto-show='scale')
									svg.how-to-start__step-icon._smile-dollar
										use(xlink:href='#icon-smile-dollar')
							p.how-to-start__step-text._3 {{ $t("landing.howtostart.list[2]") }}
						li.how-to-start__steps-item._4
							p.how-to-start__step-text._4 {{ $t("landing.howtostart.list[3]") }}
							.how-to-start__step-number-wrapper._4
								p.how-to-start__step-number 4
								.how-to-start__step-icon-wrapper._euro(data-auto-show='scale')
									svg.how-to-start__step-icon._euro
										use(xlink:href='#icon-euro')
								.how-to-start__step-icon-wrapper._ruble(data-auto-show='scale' v-if='isLocaleRu')
									svg.how-to-start__step-icon._ruble
										use(xlink:href='#icon-ruble-2')
								.how-to-start__step-icon-wrapper._dollar(data-auto-show='scale')
									svg.how-to-start__step-icon._dollar
										use(xlink:href='#icon-dollar')
					button.how-to-start__button.button._special(@click='goToLogin') {{ $t("landing.howtostart.connect") }}

		section#help.help(v-if='isLocaleRu')
			.help__container.container
				h3.help__title.title._light {{ $t("landing.help.title") }}
				.help__inner
					p.help__text(v-html='$t("landing.help.desc")')
					p.help__text(v-html='$t("landing.help.desc2")')
					a.text-decoration-none.text-center.help__button.button._special(:href='botLearn' target='_blank') {{ $t("button.openBot") }}
					.help__decorations-wrapper
						svg.help__semi-circle-img
							use(xlink:href='#icon-help-semi-circle')
						.help__mail-icons-wrapper
							//-img.help__mail-heart-img(src='@/assets/img/landing/images/help/robot.png')

		section#reviews.reviews(:class='{"d-none" : !isLocaleRu}')
			h3.reviews__title.title {{ $t("landing.reviews.title") }}
			.reviews__buttons-wrapper
				button.reviews__button(type='button' data-button-left='')
					svg.reviews__arrow-icon
						use(xlink:href='#icon-arrow-circle')
				button.reviews__button(type='button' data-button-right='')
					svg.reviews__arrow-icon
						use(xlink:href='#icon-arrow-circle')
			.reviews__carousel-wrapper
				.reviews__list.main-carousel
					.reviews__item
						//-.reviews__avatar-wrapper
							img.reviews__avatar-img(src='@/assets/img/landing/images/reviews/review-avatar-1.jpg' alt='' aria-hidden='true')
						p.reviews__item-name {{ $t('landing.reviews.list[0].name') }}
						p.reviews__item-info {{ $t('landing.reviews.list[0].info') }}
						p.reviews__item-text(v-html="$t('landing.reviews.list[0].text')")
					.reviews__item
						//-.reviews__avatar-wrapper
							img.reviews__avatar-img(src='@/assets/img/landing/images/reviews/review-avatar-2.jpg' alt='' aria-hidden='true')
						p.reviews__item-name {{ $t('landing.reviews.list[1].name') }}
						p.reviews__item-info {{ $t('landing.reviews.list[1].info') }}
						p.reviews__item-text(v-html="$t('landing.reviews.list[1].text')")
					.reviews__item
						//-.reviews__avatar-wrapper
							img.reviews__avatar-img(src='@/assets/img/landing/images/reviews/review-avatar-3.jpg' alt='' aria-hidden='true')
						p.reviews__item-name(v-html="$t('landing.reviews.list[2].name')")
						p.reviews__item-info {{ $t('landing.reviews.list[2].info') }}
						p.reviews__item-text(v-html="$t('landing.reviews.list[2].text')")
					.reviews__item
						//-.reviews__avatar-wrapper
							img.reviews__avatar-img(src='@/assets/img/landing/images/reviews/review-avatar-4.jpg' alt='' aria-hidden='true')
						p.reviews__item-name {{ $t('landing.reviews.list[3].name') }}
						p.reviews__item-info {{ $t('landing.reviews.list[3].info') }}
						p.reviews__item-text(v-html="$t('landing.reviews.list[3].text')")
						p.reviews__item-text(v-html="$t('landing.reviews.list[3].text2')")
					.reviews__item
						//-.reviews__avatar-wrapper
							img.reviews__avatar-img(src='@/assets/img/landing/images/reviews/review-avatar-5.jpg' alt='' aria-hidden='true')
						p.reviews__item-name {{ $t('landing.reviews.list[4].name') }}
						p.reviews__item-info {{ $t('landing.reviews.list[4].info') }}
						p.reviews__item-text(v-html="$t('landing.reviews.list[4].text')")
					.reviews__item
						//-.reviews__avatar-wrapper
							img.reviews__avatar-img(src='@/assets/img/landing/images/reviews/review-avatar-6.jpg' alt='' aria-hidden='true')
						p.reviews__item-name {{ $t('landing.reviews.list[5].name') }}
						p.reviews__item-info {{ $t('landing.reviews.list[5].info') }}
						p.reviews__item-text(v-html="$t('landing.reviews.list[5].text')")

		section#telegram.telegram
			.telegram__container.container._lg
				h3.telegram__title.title {{ $t('landing.telegram.title') }}
				p.telegram__text {{ $t('landing.telegram.desc') }}
				ul.telegram__list
					li.telegram__item._dashed(data-auto-show='default')
						svg.telegram__item-icon
							use(xlink:href='#icon-card-1')
						p.telegram__item-text {{ $t('landing.telegram.list[0]') }}
					li.telegram__item._shadow(data-auto-show='default')
						svg.telegram__item-icon
							use(xlink:href='#icon-card-2')
						p.telegram__item-text {{ $t('landing.telegram.list[1]') }}
					li.telegram__item._blue(data-auto-show='default')
						svg.telegram__item-icon
							use(xlink:href='#icon-card-3')
						p.telegram__item-text {{ $t('landing.telegram.list[2]') }}
					li.telegram__item._shadow(data-auto-show='default')
						svg.telegram__item-icon
							use(xlink:href='#icon-card-4')
						p.telegram__item-text {{ $t('landing.telegram.list[3]') }}
					li.telegram__item._dashed(data-auto-show='default')
						svg.telegram__item-icon
							use(xlink:href='#icon-card-5')
						p.telegram__item-text {{ $t('landing.telegram.list[4]') }}
					li.telegram__item._shadow(data-auto-show='default')
						svg.telegram__item-icon
							use(xlink:href='#icon-card-6')
						p.telegram__item-text {{ $t('landing.telegram.list[5]') }}
					li.telegram__item._blue(data-auto-show='default')
						svg.telegram__item-icon
							use(xlink:href='#icon-card-7')
						p.telegram__item-text {{ $t('landing.telegram.list[6]') }}
					li.telegram__item._shadow(data-auto-show='default')
						svg.telegram__item-icon
							use(xlink:href='#icon-card-8')
						p.telegram__item-text {{ $t('landing.telegram.list[7]') }}
					li.telegram__item._dashed(data-auto-show='default')
						svg.telegram__item-icon
							use(xlink:href='#icon-card-9')
						p.telegram__item-text {{ $t('landing.telegram.list[8]') }}
					li.telegram__item._shadow(data-auto-show='default')
						svg.telegram__item-icon
							use(xlink:href='#icon-card-10')
						p.telegram__item-text {{ $t('landing.telegram.list[9]') }}
					li.telegram__item._blue(data-auto-show='default')
						svg.telegram__item-icon
							use(xlink:href='#icon-card-11')
						p.telegram__item-text {{ $t('landing.telegram.list[10]') }}
					li.telegram__item._shadow(data-auto-show='default')
						svg.telegram__item-icon
							use(xlink:href='#icon-card-12')
						p.telegram__item-text {{ $t('landing.telegram.list[11]') }}
					li.telegram__item._dashed(data-auto-show='default')
						svg.telegram__item-icon
							use(xlink:href='#icon-card-13')
						p.telegram__item-text {{ $t('landing.telegram.list[12]') }}
					li.telegram__item._shadow(data-auto-show='default')
						svg.telegram__item-icon
							use(xlink:href='#icon-card-14')
						p.telegram__item-text {{ $t('landing.telegram.list[13]') }}
					li.telegram__item._blue(data-auto-show='default')
						svg.telegram__item-icon
							use(xlink:href='#icon-card-15')
						p.telegram__item-text {{ $t('landing.telegram.list[14]') }}
					li.telegram__item._blue(data-auto-show='default')
						svg.telegram__item-icon
							use(xlink:href='#icon-card-16')
						p.telegram__item-text {{ $t('landing.telegram.list[15]') }}
					li.telegram__item._dashed(data-auto-show='default')
						svg.telegram__item-icon
							use(xlink:href='#icon-card-17')
						p.telegram__item-text {{ $t('landing.telegram.list[16]') }}
					li.telegram__item._shadow(data-auto-show='default')
						svg.telegram__item-icon
							use(xlink:href='#icon-card-18')
						p.telegram__item-text {{ $t('landing.telegram.list[17]') }}

		section#model.model
			.model__container.container._sm
				h3.model__title.title {{ $t('landing.model.title') }}
				p.model__subtitle {{ $t('landing.model.desc') }}
				.model__item._people
					.model__item-stat-wrapper
						.model__item-stat-inner
							p.model__item-stat
								| {{ $t('landing.model.list[0].percent') }}
								span.model__item-percent %
						.model__item-icon-wrapper._people(data-auto-show='scale')
							svg.model__stat-icon._people
								use(xlink:href='#icon-people')
					.model__item-text-wrapper._people
						p.model__item-text {{ $t('landing.model.list[0].title') }}
						p.model__item-disclaimer(v-html="$t('landing.model.list[0].desc')")
				.model__item._bell
					.model__item-text-wrapper._bell
						p.model__item-text {{ $t('landing.model.list[1].title') }}
						p.model__item-disclaimer(v-html="$t('landing.model.list[1].desc')")
					.model__item-stat-wrapper
						.model__item-stat-inner
							p.model__item-stat._bell
								| {{ $t('landing.model.list[1].percent') }}
								span.model__item-percent %
						.model__item-icon-wrapper._bell(data-auto-show='scale')
							svg.model__stat-icon._bell
								use(xlink:href='#icon-bell')
				.model__item._case
					.model__item-stat-wrapper
						.model__item-stat-inner._lg
							p.model__item-stat
								| {{ $t('landing.model.list[2].percent') }}
								span.model__item-percent %
						.model__item-icon-wrapper._case(data-auto-show='scale')
							svg.model__stat-icon._case
								use(xlink:href='#icon-case')
					.model__item-text-wrapper._case
						p.model__item-text {{ $t('landing.model.list[2].title') }}
						p.model__item-disclaimer(v-html="$t('landing.model.list[2].desc')")
				.model__item._stats
					.model__item-text-wrapper._stats
						p.model__item-text {{ $t('landing.model.list[3].title') }}
						p.model__item-disclaimer(v-html="$t('landing.model.list[3].desc')")
					.model__item-stat-wrapper
						.model__item-stat-inner
							p.model__item-stat._small
								span.model__item-stat-small._top {{ $t('landing.model.in') }}
								| {{ $t('landing.model.list[3].percent') }}
								span.model__item-stat-small._bottom {{ $t('landing.model.times') }}
						.model__item-icon-wrapper._stats(data-auto-show='scale')
							svg.model__stat-icon._stats
								use(xlink:href='#icon-stats')
				.model__item._rocket
					.model__item-stat-wrapper
						.model__item-stat-inner._lg
							p.model__item-stat._small
								span.model__item-stat-small._top {{ $t('landing.model.from') }}
								| {{ $t('landing.model.list[4].percent') }}
								span.model__item-stat-small._bottom {{ $t('landing.model.minutes') }}
						.model__item-icon-wrapper._rocket(data-auto-show='scale')
							svg.model__stat-icon._rocket
								use(xlink:href='#icon-rocket')
					.model__item-text-wrapper._rocket
						p.model__item-text {{ $t('landing.model.list[4].title') }}

		section.beginner
			.beginner__container.container
				h3.beginner__title.title._light {{ $t('landing.beginner.title') }}
				.beginner__cards-list
					.beginner__card
						.beginner__card-top
							svg.beginner__emoji-icon
								use(xlink:href='#icon-emoji-think')
						p.beginner__text(v-html="$t('landing.beginner.list[0]')")
					.beginner__card
						.beginner__card-top
							svg.beginner__emoji-icon
								use(xlink:href='#icon-emoji-sweat')
						p.beginner__text(v-html="$t('landing.beginner.list[1]')")
					.beginner__card
						.beginner__card-top
							svg.beginner__emoji-icon
								use(xlink:href='#icon-emoji-ok')
						p.beginner__text(v-html="$t('landing.beginner.list[2]')")
				button.beginner__button.button._special(type='button' @click='goToLogin') {{ $t('landing.startEarn') }}

		footer.footer
			.support.footer__support
				.support__container.container
					.support__inner
						.support__left
							h3.support__title.title {{ $t('landing.footer.title') }}
							p.support__text {{ $t('landing.footer.desc') }}
						.support__right
							a.text-decoration-none.text-center.support__button.button._ghost(:href='botSupport' target='_blank')
								span(v-if='isLocaleRu') @{{$t('contacts.support_tg_bot')}}
								span(v-else) {{$t('faq.chatToUs')}}
			.footer__container.container
				.footer__top-menu
					.footer__icons
						svg.footer__logo
							use(xlink:href='#icon-logo')
					nav.footer__nav.menu
						button.menu__item.link(aria-label='Открыть меню описания сервиса' type='button' data-menu-modal-open='')
							| {{ $t('landing.whatIsIt') }}
							svg.menu__arrow-icon
								use(xlink:href='#icon-arrow-down')
						a.menu__item.link(:href='i.href' v-for='i in headerLinks' target='_blank' v-if='!(i.enHidden && locale == "en")') {{ i.title }}

					.footer__nav
					.footer__buttons-wrapper
						a.lang-switch.footer__lang-switch._sm(:data-lang-change='locale' href='javascript:void(0)' @click='changeLocale')
							//-svg.lang-switch__img.footer__lang-img(width='86' height='50')
								use(:xlink:href=`'#icon-flag-' + locale`)

						a.text-decoration-none.footer__button-telegram.button._ghost(:href='botNews' target='_blank')
							svg.footer__button-telegram-icon
								use(xlink:href='#icon-telegram')
							| Telegram

				ul.footer__bottom-menu-list
					li.footer__bottom-menu-item
						a.footer__bottom-menu-link(href='#')
							| &copy; Paywall.pw
							span.footer__current-year(data-current-year='') 2020
					li.footer__bottom-menu-item
						router-link.footer__bottom-menu-link(:to="{name: 'terms'}" target='_blank') {{ $t('h1.serviceRules') }}
					li.footer__bottom-menu-item
						router-link.footer__bottom-menu-link(:to="{name: 'privacy'}" target='_blank') {{ $t('h1.servicePrivacy') }}

		.menu-modal(data-menu-modal='')
			.menu-modal__container.container
				.menu-modal__top
					button.menu-modal__button-login.button._ghost(data-login-modal-open-xs='') {{ $t('user.login-button') }}
					span.menu-modal__logo-link(data-link='#hero')
						svg.menu-modal__logo-icon
							use(xlink:href='#icon-logo')
					button.menu-modal__button-close(type='button' data-menu-modal-close='')
						svg.menu-modal__close-icon
							use(xlink:href='#icon-close')
				ul.menu-modal__nav
					li.menu-modal__nav-item(data-menu-modal-accordion='element')
						button.menu-modal__nav-link(type='button' data-menu-modal-accordion='btn')
							| {{ $t('landing.whatIsIt') }}
							svg.menu-modal__nav-link-icon
								use(xlink:href='#icon-arrow-down')
						.menu-modal__content
							ul.menu-modal__list
								li.menu-modal__item
									span.menu-modal__link.link(data-link='#how-it-works' data-menu-modal-link='') {{ $t('landing.listMenu[0]') }}
								li.menu-modal__item
									span.menu-modal__link.link(data-link='#superpowers' data-menu-modal-link='') {{ $t('landing.listMenu[1]') }}
								li.menu-modal__item
									span.menu-modal__link.link(data-link='#payments' data-menu-modal-link='') {{ $t('landing.listMenu[2]') }}
								li.menu-modal__item
									span.menu-modal__link.link(data-link='#calculator' data-menu-modal-link='') {{ $t('landing.listMenu[3]') }}
								li.menu-modal__item
									span.menu-modal__link.link(data-link='#world' data-menu-modal-link='') {{ $t('landing.listMenu[4]') }}
							ul.menu-modal__list
								li.menu-modal__item
									span.menu-modal__link.link(data-link='#how-to-start' data-menu-modal-link='') {{ $t('landing.listMenu[5]') }}
								li.menu-modal__item(v-if='isLocaleRu')
									span.menu-modal__link.link(data-link='#help' data-menu-modal-link='') {{ $t('landing.listMenu[6]') }}
								li.menu-modal__item(v-if='isLocaleRu')
									span.menu-modal__link.link(data-link='#reviews' data-menu-modal-link='') {{ $t('landing.listMenu[7]') }}
								li.menu-modal__item
									span.menu-modal__link.link(data-link='#telegram' data-menu-modal-link='') {{ $t('landing.listMenu[8]') }}
								li.menu-modal__item
									span.menu-modal__link.link(data-link='#model' data-menu-modal-link='') {{ $t('landing.listMenu[9]') }}
					li.menu-modal__nav-item(v-for='i in headerLinks' v-if='!(i.enHidden && locale == "en")')
						a.menu__item.link(:href='i.href' target='_blank')
							span.menu-modal__nav-link {{ i.title }}
				button.menu-modal__button-start.button._special( @click='goToLogin') {{ $t('landing.startEarn') }}

		.mailing(data-mailing-modal='')
			.mailing__overlay(data-mailing-modal-overlay='')
				.mailing__modal
					button.mailing__button-close(type='button' data-mailing-modal-close='')
						svg.menu-modal__button-close-icon
							use(xlink:href='#icon-close')
					p.mailing__text {{ $t('landing.mailing') }}
					form.mailing__form
						input.mailing__input-email(type='email' :placeholder='$t("errors.required.email2")')
						button.mailing__button-submit.button(type='submit') {{ $t('landing.startEarn') }}
		modal-cookie

		.mailing#modal-login(data-login-modal='')
			.mailing__overlay(data-login-modal-overlay='')
				.mailing__modal
					button.mailing__button-close(type='button' data-login-modal-close='')
						svg.menu-modal__button-close-icon
							use(xlink:href='#icon-close')
					p.mailing__text {{ $t('landing.modalLogin.title') }}
					form.mailing__form
						button._ghost.login__button-submit.button(@click='goToLogin') {{ $t('landing.modalLogin.asAdmin') }}
						button._ghost.login__button-submit.button(@click='goToBotPay') {{ $t('landing.modalLogin.asSubscriber') }}
					p.login__footer
						| {{ $t('landing.modalLogin.questions') }}&nbsp;
						router-link.text-decoration-none(:to="{name: 'faq'}" target='_blank')
							b FAQ
</template>

<style src="@/assets/css/landing/styles.css?88033041ea4530d4eb2b840ab739b25ccc"></style>

<script>

import { mapActions } from 'vuex'
import {locale} from '@/main'
import {getDirection, setDirection} from '@/utils'
import {localeOptions} from '@/constants/config'
import {STORAGE} from '@/api/storage'
import ModalCookie from '@/components/Modals/Cookie'

export default {
	metaInfo : {
		titleTemplate: STORAGE.getLang() == 'ru' ? 'Paywall - монетизация Telegram-ĸаналов по подписке.' : 'Paywall - Content monetization service',
	},
	components: {
		ModalCookie
	},
	data() {
		return {
			isShownCookieModal	: true,
			modalLogin	: {},
			modalCookie : {},
			headerLinks: [],
			listHero: [
				{size: 27},
				{size: 28},
				{size: 32},
				{size: 25},
				{size: 29},
				{size: 32},
				{size: 26},
				{size: 33}
			],
			listSubscribers: [],
			listHowItWorks : [],
			locale 	: locale
		}
	},
	beforeMount () {
		this.redirectToLoginIfMerchantPath();
		this.loadBundle();
		this.setHeaderLinks();
		this.setHowItWorksList();
		this.setSubscribersList();
	},

	destroyed () {

	},
	computed: {

	},
	mounted () {
		if (this.$route.hash) {
			setTimeout(() => this.scrollTo(this.$route.hash), 100)
		}

		this.rememberPageView();
		this.initModalLogin();
		// this.initModalCookie();
	},

	methods : {
		...mapActions({
			setLang	: 'setLang'
		}),
		redirectToLoginIfMerchantPath () {
			if ( window.location.href.includes('merchant') ) {
				if( this.me && this.me.id ) return this.$router.replace({name: 'projects'})

				return this.goToLogin();
			}
		},

		scrollTo: function (hashtag) {
			setTimeout(() => { location.href = hashtag }, 100)
		},

		goToLogin (e) {
			if ( e ) {
				e.preventDefault();
				e.stopPropagation();
			}

			window.location.href = this.loginUrl;
		},

		goToBotPay (e) {
			e.preventDefault();
			e.stopPropagation();
			window.location.href = this.botPay;
		},
		loadBundle () {
			/*let tag = document.createElement("script");
			tag.src = "/assets/img/landing/js/bundle.js";
			document.getElementsByTagName("head")[0].appendChild(tag);*/
		},

		changeLocale() {
			const currentDirection = getDirection().direction
			let l = _.find(localeOptions, l => l.id != this.locale);
			this.setLang(l.id)
			this.locale = l.id;
			window.location.reload();
		},

		setHeaderLinks () {
			this.headerLinks = [
				{title: this.$t('h1.help'), href: this.botSupport},
				{title: 'FAQ', href:'/faq'},
				{title: this.$t('h1.forSubcsribers'), href:'/subscriber'},
				{title: this.$t('h1.blog'), href:'//paywall.pw' + this.$t('contacts.blog'), enHidden: true}
			]
		},

		setHowItWorksList () {
			this.listHowItWorks 	= this.$t('landing.listHowItWorks');
		},

		setSubscribersList () {
			this.listSubscribers 	= this.$t('landing.listSubscribers');
		},

		initModalLogin () {
			this.modalLogin = {
				modalTrigger : document.querySelector("[data-login-modal-open"+ (window.innerWidth < 1025 ? "-xs" : "") +"]"),
				modalOverlay : document.querySelector("[data-login-modal-overlay]"),
				mailingModal : document.querySelector("[data-login-modal]"),
				buttonClose : document.querySelector("[data-login-modal-close]"),
			}

			this.modalLogin.modalOpenHandler = () => {
				this.modalLogin.mailingModal.classList.add("_open"),
					gsap.from(this.modalLogin.modalOverlay, {
						opacity: 0,
						duration: .3
				})
			}

			this.modalLogin.modalCloseHandler = () => {
				this.modalLogin.mailingModal.classList.remove("_open")
			}

			this.modalLogin.modalTrigger.addEventListener("click", this.modalLogin.modalOpenHandler),
			this.modalLogin.buttonClose.addEventListener("click", this.modalLogin.modalCloseHandler),
				document.addEventListener("keydown", ((t) => {
					"Escape" === t.key && this.modalLogin.modalCloseHandler()
				}
			)),
			this.modalLogin.modalOverlay.addEventListener("click", ((t) => {
					t.target === t.currentTarget && this.modalLogin.modalCloseHandler()
				}
			))
		},
		/* initModalCookie () {
			this.modalCookie = {};
			this.modalCookie.cookieMessage 	= document.querySelector(".cookie");
			this.modalCookie.cookieButton 	= this.modalCookie.cookieMessage.querySelector(".cookie__button-thanks");

			this.modalCookie._setCookie = () => {
				this.modalCookie.cookieMessage.classList.add("_active"),
				this.modalCookie.cookieButton.addEventListener("click", this.modalCookie._accept);
			}

			this.modalCookie._accept = () => {
				var t, n;
				t = window.location.href,
				n = n || !1,
				t = t.replace(/(https?:\/\/)?(www.)?/i, ""),
				n || (t = (t = t.split(".")).slice(t.length - 2).join(".")),
				-1 !== t.indexOf("/") && t.split("/")[0];
				this.$cookies.set("cookies-info-shown", "1", '1Y'),
				gsap.to(this.modalCookie.cookieMessage, {
					scale: 0,
					opacity: 0,
					duration: .2,
					onComplete: function() {
						this.modalCookie.cookieMessage.classList.remove("_active")
					}
				}),
				this.modalCookie.cookieButton.removeEventListener("click", this.modalCookie._accept)
			}

			if ( this.$cookies.get("cookies-info-shown") == null )
				this.modalCookie._setCookie()
		} */


	},
	watch : {
		'$i18n.locale'(to, from) {
			this.setHeaderLinks();
			this.setHowItWorksList();
			this.setSubscribersList();
		}
	}
}
</script>

<style lang='scss'>
	:root{
		--foreground-color: #fff;
		--brand-bg-primary: #EFF7FF;
		--main-text-color: #082846;
		--border-radius-rounded: 8px;
		--primary-text-color: #52A7F9;
		--btn-small-box-shadow: 0px 2px 7px 0px rgba(#000, 0.10);
		--animation-time-short: 200mc;
		--button-text-color: #fff;
	}

	.btn-long {
		padding-left: 32px !important;
		padding-right: 32px !important;
	}

	.cookie {
		max-width: 322px !important;
	}

	.cookie__text {
		color: #8292A1;
	}
</style>

<style lang='scss' scoped>
	.d-none {
		display: none !important;
	}
	.text-decoration-none {
		text-decoration: none;
	}
	.support__button,
	.login__button-submit {
		a:hover { color: #fff; }
	}

	.login__button-submit {
		margin-bottom: 43px;
	}

	.login__footer {
		font-size: 26px;
		text-align: center;
	}

	.text-center {
		text-align: center;
	}

	@media (max-width: 768px) {
		.mailing__modal {
			top: auto;
			bottom: 0;
			left: 0;
			right: 0;
			transform: none;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;

			.mailing__text {
				text-align: center;
				margin-top: 48px;
			}

			.mailing__button-close {
				width: 24px;
				height: 24px;
			}

			.login__button-submit {
				margin-bottom: 30px;
			}

			.login__footer {
				font-size: 16px;
				margin-bottom: 30px;
			}
		}
		.menu-modal__container {
			margin-bottom: 80px;
		}

		.model__item-stat._small {
			gap: 7px;
		}
	}
</style>
